

















import {Component, Prop, Vue} from "vue-property-decorator";
import BasicForm from "@/layout/form/BasicForm.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import TbbNumarasi from "@/components/inputs/TbbNumarasi.vue";
import BaroNumarasi from "@/components/inputs/BaroNumarasi.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";

@Component({
    components: {IlPicker, BaroNumarasi, TbbNumarasi, TcKimlikNumarasi, BasicForm}
})
export default class VekilSorgula extends Vue {


}
