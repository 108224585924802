








import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class TbbNumarasi extends Mixins(SimpleInputMixin) {

}
