import { render, staticRenderFns } from "./TbbNumarasi.vue?vue&type=template&id=c2e4d794&scoped=true&"
import script from "./TbbNumarasi.vue?vue&type=script&lang=ts&"
export * from "./TbbNumarasi.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e4d794",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
